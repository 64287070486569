import { computed, ref } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";
const { ContractStatus, ContractType, Contract } = Vue.$FeathersVuex.api;
import { snackbar } from "@/components/base/snackbar/useSnackbar";
import { store } from "@/store";

export function useContractStatuses() {
  const contractStatusParams = computed(() => {
    return {
      query: {
        $sort: {
          display_order: 1
        }
      }
    };
  });

  const { items: contractStatuses } = useFind({
    model: ContractStatus,
    params: contractStatusParams,
    qid: "contractStatuses"
  });

  return {
    contractStatuses
  };
}

export function useContractTypes() {
  const contractTypeParams = computed(() => {
    return {
      query: {
        $sort: {
          display_order: 1
        }
      }
    };
  });

  const { items: contractTypes } = useFind({
    model: ContractType,
    params: contractTypeParams,
    qid: "contractTypes"
  });

  return {
    contractTypes
  };
}

export function getContractCount() {
  const contractCount = ref(0);

  try {
    let params = { query: {} };
    store.dispatch("contracts/count", params).then((response) => {
      contractCount.value = response;
    });
  } catch (error) {
    console.log(error);
  }

  return {
    contractCount
  };
}

export function useContractDialog() {
  let isContractEditDialogOpen = ref(false);
  let itemToEdit = ref({});
  const editItem = (item) => {
    itemToEdit.value = { ...item };
    isContractEditDialogOpen.value = true;
  };

  const createItem = () => {
    let contract = new Contract({});
    let contractClone = contract.clone();
    itemToEdit.value = { ...contractClone };
    isContractEditDialogOpen.value = true;
  };

  const processFormData = async (form) => {
    try {
      let contract = new Contract(form);
      let contractClone = contract.clone();
      if (contractClone.fulcrum_id) {
        closeDialog(false);
        await contractClone.patch();
        await contractClone.commit();

        snackbar({
          text: `${contractClone.contract_num} was successfully updated.`,
          color: "success"
        });
      } else {
        closeDialog(false);
        await contractClone.create();
        snackbar({
          text: `${contractClone.contract_num} was successfully created.`,
          color: "success"
        });
      }
    } catch (error) {
      snackbar({
        text: `Error encountered while trying to save this contract. Please contact your Administrator.`,
        color: "error"
      });
    }
  };

  const closeDialog = (isEditDialogOpen) => {
    isContractEditDialogOpen.value = isEditDialogOpen;
  };

  return {
    isContractEditDialogOpen,
    itemToEdit,
    editItem,
    createItem,
    processFormData,
    closeDialog
  };
}
