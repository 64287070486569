<script>
  import { mdiFileDocumentEditOutline, mdiMagnify, mdiPlus } from "@mdi/js";
  import { defineComponent, ref, watch } from "@vue/composition-api";
  import Vue from "vue";

  // components
  import AppLoading from "@/components/base/loading/AppLoading.vue";
  import Breadcrumbs from "@/components/common/breadcrumbs/Breadcrumbs.vue";
  import { useLogger } from "@/composables/useLogger";
  import { usePagination } from "@/composables/usePagination";
  import { contractStatusColor } from "@/helpers/statusColors";
  import { store } from "@/store";

  import {
    useContractDialog,
    useContractStatuses,
    useContractTypes
  } from "../useContract";
  import ContractListEdit from "./ContractListEdit.vue";
  import {
    useContractDatatable,
    useContractList,
    useContractListBreadcrumbs
  } from "./useContractList";

  export default defineComponent({
    components: {
      Breadcrumbs,
      ContractListEdit,
      AppLoading
    },
    setup() {
      const logger = useLogger();
      const filterText = ref(null);
      const selectedContractStatus = ref(null);
      const selectedContractType = ref(null);
      const {
        contracts,
        isContractLoading,
        haveLoaded,
        latestQuery,
        findContracts,
        contractsParams
      } = useContractList();
      const { breadcrumbs } = useContractListBreadcrumbs();
      const { headers } = useContractDatatable();
      const { options, itemsPerPageOptions } = usePagination({
        initialItemsPerPage: 25,
        initialSortBy: ["contract_num"],
        itemsPerPageOptions: [5, 10, 25, 50],
        storePath: "contracts"
      });
      const { contractStatuses } = useContractStatuses();
      const { contractTypes } = useContractTypes();
      const {
        isContractEditDialogOpen,
        itemToEdit,
        editItem,
        createItem,
        processFormData,
        closeDialog
      } = useContractDialog();
      const { Contract } = Vue.$FeathersVuex.api;

      watch(options, (value) => {
        logger.debug("Pagination options updated", value);
        store.dispatch("contracts/updatePagination", value);
      });

      watch(filterText, (value) => {
        logger.debug("Filter text changed", value);
        store.dispatch("contracts/filterText", value);
      });

      watch(selectedContractStatus, (value) => {
        logger.debug("Contract status filter changed", value);
        store.dispatch("contracts/filterContractStatus", value);
      });

      watch(selectedContractType, (value) => {
        logger.debug("Contract type filter changed", value);
        store.dispatch("contracts/filterContractType", value);
      });

      Contract.on("created", async (item) => {
        logger.debug("Contract created", item);
      });

      Contract.on("updated", async (item) => {
        logger.debug("Contract updated", item);
      });

      Contract.on("patched", async (item) => {
        logger.debug("Contract patched", item);
      });

      return {
        filterText,
        selectedContractStatus,
        selectedContractType,

        // useContractList
        contracts,
        isContractLoading,
        haveLoaded,
        latestQuery,
        findContracts,
        contractsParams,

        // useContractDatatable
        headers,

        // useContractListBreadcrumbs
        breadcrumbs,

        // usePagination
        options,
        itemsPerPageOptions,

        // useContractStatuses
        contractStatuses,

        // useContractTypes
        contractTypes,

        // statusColors
        contractStatusColor,

        // icons
        icons: {
          mdiFileDocumentEditOutline,
          mdiMagnify,
          mdiPlus
        },

        // useContract
        isContractEditDialogOpen,
        itemToEdit,
        editItem,
        createItem,
        processFormData,
        closeDialog
      };
    }
  });
</script>

<template>
  <div v-if="haveLoaded">
    <breadcrumbs class="mb-5" :items="breadcrumbs" />
    <v-card id="invoice-list" flat>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row class="pb-5">
          <v-col cols="12" md="3" lg="2">
            <!-- create contract -->
            <v-btn color="primary" class="me-3" @click="createItem">
              <span>Add Contract</span>
            </v-btn>
          </v-col>

          <v-col cols="12" md="9" lg="5" offset-lg="1">
            <v-text-field
              v-model="filterText"
              :append-icon="icons.mdiMagnify"
              clearable
              single-line
              dense
              outlined
              hide-details
              placeholder="Search Contract Number, FAP Number, or Contract Description"
              class="invoice-list-search me-3 pb-5"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6" lg="2">
            <v-select
              v-model="selectedContractType"
              :items="contractTypes"
              item-value="display_type"
              item-text="display_type"
              single-line
              outlined
              dense
              hide-details
              clearable
              placeholder="Select Type"
              class="invoice-list-status me-3 pb-5"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6" lg="2">
            <v-select
              v-model="selectedContractStatus"
              :items="contractStatuses"
              item-value="display_status"
              item-text="display_status"
              single-line
              outlined
              dense
              hide-details
              clearable
              placeholder="Select Status"
              class="invoice-list-status"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- data table -->
      <v-data-table
        v-if="contracts"
        :headers="headers"
        :items="latestQuery.response.data"
        :options.sync="options"
        :server-items-length="latestQuery.response.total"
        :loading="isContractLoading"
        loading-text="Loading... Please wait"
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- contract number -->
        <template #[`item.contract_num`]="{ item }">
          {{ item.contract_num }}
        </template>

        <!-- FAP number -->
        <template #[`item.fap_num`]="{ item }">
          {{ item.fap_num }}
        </template>

        <!-- contract description -->
        <template #[`item.contract_desc`]="{ item }">
          {{ item.contract_desc }}
        </template>

        <!-- contract type -->
        <template #[`item.contract_type`]="{ item }">
          {{ item.contract_type }}
        </template>

        <!-- contract status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="contractStatusColor(item.status)"
            :class="`${contractStatusColor(item.status)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- card actions -->
        <template #[`item.actions`]="{ item }">
          <v-icon small class="me-2" @click="editItem(item)">
            {{ icons.mdiFileDocumentEditOutline }}
          </v-icon>
        </template>
      </v-data-table>

      <!-- edit contract dialog -->
      <contract-list-edit
        :is-contract-edit-dialog-open.sync="isContractEditDialogOpen"
        :loading.sync="isContractLoading"
        :edit-item="itemToEdit"
        @save="processFormData"
        @close-dialog="closeDialog"
      ></contract-list-edit>
    </v-card>
  </div>
  <div v-else>
    <app-loading></app-loading>
  </div>
</template>
